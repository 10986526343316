.section-text-container h2:first-child,
.quote-text-container h3:first-child,
.quote-text-container h4:first-child {
  padding-top: 0;
}

.quote-text-container h2:first-child,
.quote-text-container h3:first-child,
.quote-text-container h4:first-child {
  padding-top: 0;
}

.section-text-container span:last-of-type:empty {
  display: none;
}

.section-text-container p:empty {
  display: none;
}

.section-text-container a:last-child,
.section-text-container span:last-child,
.section-text-container p:last-child {
  margin-bottom: 0;
}

.quote-text-container h2:first-child,
.quote-text-container h3:first-child,
.quote-text-container h4:first-child {
  padding-top: 0;
}

.quote-text-container span:last-of-type:empty,
.quote-text-container p:empty {
  display: none;
}

.quote-text-container a:last-child,
.quote-text-container span:last-child,
.quote-text-container p:last-child {
  margin-bottom: 0 !important;
}
